import EventEmitter from '@/events/EventEmitter';
import type {
  SocketUpdateEvent,
  SocketUpdateEventWithOrgId,
  SocketUpdateEventWithoutOrgId,
} from '@/events/SocketUpdateEvents';
import type {OrgId} from '@/model/org/types';

class _WebSocketEventEmitter extends EventEmitter {
  override emit<T extends SocketUpdateEvent['type']>(
    eventName: T,
    data: MaybeArray<Extract<SocketUpdateEvent, {type: T}>['data']>,
    ...[options]: T extends SocketUpdateEventWithOrgId['type']
      ? [{clientContext?: {optimisticId: string}; orgId: OrgId}]
      : [{clientContext?: {optimisticId: string}; orgId?: never}] | []
  ): boolean {
    const payloadData = Array.isArray(data) ? data : [data];
    return super.emit(eventName, {
      data: payloadData,
      ...options,
    });
  }
}

const WebSocketEventEmitter = new _WebSocketEventEmitter();
export default WebSocketEventEmitter;
